<template>
  <div class="blog">
    <b-container>
      <b-row>
        <b-col sm="12" lg="7" class="blog-data">
          <router-view />
        </b-col>
        <b-col sm="12" xl="1" class="d-none d-xl-block"></b-col>
        <b-col sm="12" lg="5" xl="4" class="px-lg-0">
          <GetStarted/>
          <TopProviders class="w-100 mt-5"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import GetStarted from '@/components/GetStarted.vue'
import TopProviders from '@/components/TopProviders.vue'

export default {
  components: {
    GetStarted,
    TopProviders
  }
}
</script>

<style lang="scss">

.blog {
  padding-top: 30px;
  padding-bottom: 30px;
  @media only screen and (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
</style>

